<template>
  <div class="result-details-empty">
    <Loader v-if="isLoading" />
    <div v-else-if="data && data.report">
      <vs-button
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-arrow-left"
        style="margin-bottom: 15px"
        @click="onBackClick"
        >{{ $l.get("dictionary.go-back") }}</vs-button
      >
      <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-alert
            active="true"
            color="warning"
            style="text-align: center; margin-bottom: 15px"
            >{{ $l.get("result-details-empty.warning") }}.</vs-alert
          >
          <vs-card>
            <div slot="header">
              <h3>{{ title }}</h3>
              <p>{{ subtitle }}</p>
            </div>
            <div>{{ result }}</div>
          </vs-card>
          <vs-card>
            <div slot="header">
              <h3>{{ $l.get("dictionary.description") }}</h3>
            </div>
            <div>
              <div v-html="description" style="margin: 10px"></div>
            </div>
          </vs-card>
          <vs-card>
            <div slot="header">
              <h3>{{ $l.get("result-details.references") }}</h3>
            </div>
            <div v-html="references"></div>
          </vs-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import ResultDetailsEmptyScreenController from "@/adapters/controllers/screen-result-details-empty";
import InitResultDetailsEmptyScreenInteractor from "@/business/result-details-empty/init-result-details-empty-screen";
import Loader from "@/application/components/Loader";
export default {
  data() {
    return {
      interactors: {
        initResultDetailsEmptyScreen: null
      },
      data: null,
      isLoading: false
    };
  },
  components: { Loader },
  beforeMount() {
    this.controller = this.$injector.get(ResultDetailsEmptyScreenController);

    //{ INTERACTORS
    this.interactors.initResultDetailsEmptyScreen = this.$injector.get(
      InitResultDetailsEmptyScreenInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initResultDetailsEmptyScreen.handle(id);
  },
  computed: {
    title() {
      return this.$l.getByLang(
        this.data.report.titlePtBr,
        this.data.report.titleEnUs,
        this.data.report.titleEsEs
      );
    },
    subtitle() {
      return this.$l.getByLang(
        this.data.report.subtitlePtBr,
        this.data.report.subtitleEnUs,
        this.data.report.subtitleEsEs
      );
    },
    result() {
      return this.$l.getByLang(
        this.data.report.resultPtBr,
        this.data.report.resultEnUs,
        this.data.report.resultEsEs
      );
    },
    description() {
      return this.$l.getByLang(
        this.data.report.descriptionHtmlPtBr,
        this.data.report.descriptionHtmlEnUs,
        this.data.report.descriptionHtmlEsEs
      );
    },
    references() {
      if (!(this.data && this.data.report)) return "";
      const referencesList = this.data.report.bibliographicReferencesHtml.split(
        "\n"
      );
      let references = "";
      for (const reference of referencesList) {
        references += `<p>${reference}</p><br />`;
      }
      return references;
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onBackClick() {
      window.history.back();
    }
  }
};
</script>
